import React from 'react'
import Nav  from './Nav';

const PreOwnedInventory = () => {
  return (
    <>
      <Nav />
    </>
  )
}

export default PreOwnedInventory
