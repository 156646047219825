import { Disclosure } from '@headlessui/react'
import  Carousel  from './Carousel'
import InventorySearch from './InventorySearch'
import RecentViews from './RecentViews'
import Footer from './Footer'



const HomePage = () => {


  return (
    <Disclosure>

        {({open}) =>(
            <section className='dark:bg-gray-900 h-48 md:block md:w-[1023px] lg:w-[1279px] xl:w-[1535px] 2xl:w-[2506px] '>
              <div className='px-6 pt-6 md:flex md:justify-between md:items-center lg:px-20 xl:pr-40 2xl:pr-96 2xl:pl-80
                               '>
                        <a href='/'>
                          <img  
                              src="https://res.cloudinary.com/doehyebmw/image/upload/v1699580908/Kommander/Logo/dealer-logo-min_hy5h6s.png" 
                              className="w-40 md:h-28 md:w-72 lg:w-[600px]" alt="Flowbite Logo"/> 
                        </a>
                        <div className='inline-flex absolute top-5 right-5 md:hidden'>
                            <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-red-700 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                              <span className='sr-only'>Open main menu</span>
                              {open ? (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="block w-6 h-6" aria-hidden={true}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                              ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className=" w-6 h-6 block " aria-hidden={false}>
                                <path strokeLinecap="round" strokeLinejoin="round"
                                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                                </svg>
                              )}
                            </Disclosure.Button>
                   </div>

                   <div className="flex flex-col mt-7 ml-2 md:items-center md:text-lg">
                      <div className='font-bold  dark:text-white'>
                        <a href="tel:5541251234">(032) 260-5895</a>
                      </div>
                      <div className='flex items-start  mt-2 md:w-80'>
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" 
                           className="w-3 h-3 mt-1 md:w-10 md:h-6 text-white">
                           <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                           <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                           </svg>
                            <a href="#/" className="text-sm font-sm dark:text-gray-400  md:text-lg">4220 W. OPPORTUNITY WAY, SUITE 125 PHOENIX, AZ 85086</a>     
                      </div>          
                   </div>  
              </div>

        <Disclosure.Panel className=" dark:bg-red-700 bg-cover translate-y-4 relative z-10 md:hidden">
        <div className="flex flex-col ">
            <ul className="font-medium mt-5 space-x-8 flex flex-col items-baseline text-sm relative z-10 gap-4">
                <li>
                    <a href="/home" className="text-gray-900 active:text-black dark:text-white hover:underline-offset-2" aria-current="page">HOME</a>
                </li>
                {/* showroom */}
                <li 
                    className='group relative cursor-pointer'
                        >
                    <button 
                           className='text-white dark:text-white hover:underline focus:outline-none'>
                        SHOWROOM
                    </button>
              
                    <ul className='invisible absolute z-10  flex w-60 flex-col bg-red-700 py-1 translate-x-32 -translate-y-11 shadow-xl group-hover:visible ' >

                        <li><a href='/showroom/manufacturer_models' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                              MANUFACTURER MODELS
                            </a>
                        </li>
                        <li><a href='/showroom/all_inventory' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               ALL INVENTORY
                            </a>
                        </li>
                        <li><a href='/showroom/new_inventory' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               NEW INVENTORY
                            </a>
                        </li>
                        <li><a href='/showroom/preowned' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               PRE-OWNED INVENTORY
                            </a>
                        </li>
                        <li><a href='/showroom/promotions' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               PROMOTIONS 
                            </a>
                        </li>
                        <li><a href='/showroom/value' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               VALUE YOUR TRADE
                            </a>
                        </li>
                        <li><a href='/showroom/request' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               REQUEST A MODEL
                            </a>
                        </li>
                        <li><a href='/showroom/schedule' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               SCHEDULE A TEST RIDE
                            </a>
                        </li>
                

                    </ul>
                
                </li>
                {/* end of showroom */}


                {/* shop online */}
                <li 
                    className='group relative cursor-pointer'
                        >
                    <button 
                 
                           className='text-white dark:text-white hover:underline focus:outline-none'>
                        SHOP ONLINE
                    </button>
              
                    <ul className='invisible absolute z-10 flex w-60 flex-col bg-red-700 py-1 translate-x-32 -translate-y-11   shadow-xl group-hover:visible ' >

                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                              CATALOGS
                            </a>
                        </li>
                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                              PARTS FINDER
                            </a>
                        </li>
                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               KOMMANDER PARTS
                            </a>
                        </li>
                        
                    </ul>
                
                </li>
                {/* end of shop online */}

                {/* kommander */}
                <li 
                    className='group relative cursor-pointer'
                        >
                    <button 
                 
                           className='text-white dark:text-white hover:underline focus:outline-none'>
                        KOMMANDER
                    </button>
              
                    <ul className='invisible absolute z-10 flex w-60 flex-col bg-red-700 py-1 translate-x-32 -translate-y-11  shadow-xl group-hover:visible ' >

                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                             GP1
                            </a>
                        </li>
                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                              TURBO KITS
                            </a>
                        </li>
                

                    </ul>
                
                </li>
                {/* end of kommander */}

                {/* dealer services */}
                <li 
                    className='group relative cursor-pointer'
                        >
                    <button 
                 
                           className='text-white dark:text-white hover:underline focus:outline-none'>
                        DEALER SERVICES
                    </button>
              
                    <ul className='invisible absolute z-10 flex w-60 flex-col bg-red-700 py-1 translate-x-32 -translate-y-11  shadow-xl group-hover:visible ' >

                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                              SERVICE DEPARTMENT
                            </a>
                        </li>
                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               SCHEDULE SERVICE
                            </a>
                        </li>
                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               WARRANTY & RECALL LOOK-UP
                            </a>
                        </li>
                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                              PARTS DEPARTMENT
                            </a>
                        </li>
                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               REQUEST PARTS
                            </a>
                        </li>
                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               SECURE FINANCING
                            </a>
                        </li>
                

                    </ul>
                
                </li>
                {/* end of dealer services */}

                {/* dealer info */}
                <li 
                    className='group relative cursor-pointer'
                        >
                    <button 
                 
                           className='text-white dark:text-white hover:underline focus:outline-none'>
                        DEALER INFO
                    </button>
              
                    <ul className='invisible absolute z-10 flex w-60 flex-col bg-red-700 py-1 translate-x-32 -translate-y-11  shadow-xl group-hover:visible ' >

                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                              OUR DEALERSHIP
                            </a>
                        </li>
                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                              MAP & HOURS
                            </a>
                        </li>
                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               EVENTS
                            </a>
                        </li>
                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               REVIEWS
                            </a>
                        </li>
                        <li><a href='#/' 
                               className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                               CUSTOMER SURVEY
                            </a>
                        </li>
                        

                    </ul>
                
                </li>
                {/* end of dealer info */}
               
            </ul>
            <hr className='my-5 mt-12'></hr>
            <div className='z-0 space-x-6 mb-5 ml-8'>
                <button className="relative border-2 font-medium border-gray-400 bg-transparent py-2.5 px-5 uppercase text-gray-200 transition-colors before:absolute before:left-0 before:top-0 before:z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-cyan-300 before:transition-transform before:duration-300 before:content-[''] hover:text-white before:hover:scale-x-100 hover:border-cyan-300 hover:bg-cyan-300 hover:bg-opacity-20">
                    <a href="#/">CONTACT US</a>
                </button>
            </div>
        </div>

        </Disclosure.Panel>
            {/* desktop responsive */}
        <div className='dark:bg-red-700  mt-12 md:block hidden '>
            <div className="py-5 flex justify-between items-center px-5 md:px-11 lg:pr-28  xl:pr-48 2xl:pr-96">
                <ul className=' flex relative z-10 gap-4 text-sm md:gap-6 lg:text-base lg:gap-6 xl:text-xl lg:pl-16 xl:pl-10 2xl:pl-48'>
                   <li className="text-gray-900 active:text-black dark:text-white hover:underline-offset-2 cursor-pointer">
                        <a href="/home" aria-current="page">HOME</a>
                   </li>
                                {/* showroom */}
                    <li className='group relative cursor-pointer'>
                      <button className='text-white dark:text-white hover:underline focus:outline-none'>
                          SHOWROOM
                      </button>
                       <ul className='invisible absolute z-10  flex w-60 flex-col bg-red-700 py-1  shadow-xl group-hover:visible '>
                                <li><a href='/showroom/manufacturer_models'
                                      className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                      MANUFACTURER MODELS
                                  </a>
                                </li>
                                <li><a href='/showroom/all_inventory'
                                      className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                      ALL INVENTORY
                                  </a>
                                </li>
                                <li><a href='/showroom/new_inventory'
                                      className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                      NEW INVENTORY
                                  </a>
                                </li>
                                <li><a href='/showroom/preowned'
                                      className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                      PRE-OWNED INVENTORY
                                  </a>
                                </li>
                                <li><a href='/showroom/promotions'
                                      className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                      PROMOTIONS
                                  </a>
                                </li>
                                <li><a href='/showroom/value'
                                      className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                      VALUE YOUR TRADE
                                  </a>
                                </li>
                                <li><a href='/showroom/request'
                                      className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                      REQUEST A MODEL
                                  </a>
                                </li>
                                <li><a href='/showroom/schedule'
                                      className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                      SCHEDULE A TEST RIDE
                                  </a>
                                </li>
                        </ul>         
                    </li>
                                {/* end of showroom */}
                                {/* shop online */}
                    <li className='group relative cursor-pointer'>
                        <button className='text-white dark:text-white hover:underline focus:outline-none'>
                            SHOP ONLINE
                        </button>

                        <ul
                            className='invisible absolute z-10 flex w-60 flex-col bg-red-700 py-1  shadow-xl group-hover:visible '>

                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    CATALOGS
                                </a>
                            </li>
                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    PARTS FINDER
                                </a>
                            </li>
                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    KOMMANDER PARTS
                                </a>
                            </li>

                        </ul>

                    </li>
                                {/* end of shop online */}
                                {/* kommander */}
                    <li className='group relative cursor-pointer'>
                        <button className='text-white dark:text-white hover:underline focus:outline-none'>
                            KOMMANDER
                        </button>

                        <ul
                            className='invisible absolute z-10 flex w-60 flex-col bg-red-700 py-1  shadow-xl group-hover:visible '>

                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    GP1
                                </a>
                            </li>
                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    TURBO KITS
                                </a>
                            </li>


                        </ul>

                    </li>
                                {/* end of kommander */}
                                {/* dealer services */}
                    <li className='group relative cursor-pointer'>
                        <button className='text-white dark:text-white hover:underline focus:outline-none'>
                            DEALER SERVICES
                        </button>

                        <ul
                            className='invisible absolute z-10 flex w-60 flex-col bg-red-700 py-1  shadow-xl group-hover:visible '>

                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    SERVICE DEPARTMENT
                                </a>
                            </li>
                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    SCHEDULE SERVICE
                                </a>
                            </li>
                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    WARRANTY & RECALL LOOK-UP
                                </a>
                            </li>
                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    PARTS DEPARTMENT
                                </a>
                            </li>
                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    REQUEST PARTS
                                </a>
                            </li>
                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    SECURE FINANCING
                                </a>
                            </li>


                        </ul>

                    </li>
                                {/* end of dealer services */}
                                {/* dealer info */}
                    <li className='group relative cursor-pointer'>
                        <button className='text-white dark:text-white hover:underline focus:outline-none'>
                            DEALER INFO
                        </button>

                        <ul
                            className='invisible absolute z-10 flex w-60 flex-col bg-red-700 py-1  shadow-xl group-hover:visible '>

                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    OUR DEALERSHIP
                                </a>
                            </li>
                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    MAP & HOURS
                                </a>
                            </li>
                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    EVENTS
                                </a>
                            </li>
                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    REVIEWS
                                </a>
                            </li>
                            <li><a href='#/'
                                    className='my-2 block border-b border-gray-400 py-1 px-2 font-semibold text-white hover:text-gray-700 md:mx-2'>
                                    CUSTOMER SURVEY
                                </a>
                            </li>


                        </ul>

                    </li>
                                {/* end of dealer info */}
        
                </ul>
                <div className='z-0 text-sm '>
                    <button
                        className="relative border-2 font-medium border-gray-400 bg-transparent py-2.5 px-5 uppercase
                                text-gray-200 transition-colors before:absolute before:left-0 before:top-0 before:z-10 
                                  before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-cyan-300 
                                  before:transition-transform before:duration-300 before:content-[''] hover:text-white 
                                  before:hover:scale-x-100 hover:border-cyan-300 hover:bg-cyan-300 hover:bg-opacity-20
                                  lg:text-xl">
                        <a href="#/">CONTACT US</a>
                    </button>
                </div>
            </div>                    
        </div>                        
      
        {/* carousel section */}
       <Carousel />
        {/* InventorySearch section  */}
       <InventorySearch /> 
         {/* RecentViews */}
        {/* <RecentViews /> */}
        <RecentViews />
        {/* Footer */}
        <Footer />
      </section>

        
        )}
       
       
    </Disclosure>
    
  )
}

export default HomePage

