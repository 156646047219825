import React from 'react'
import Nav from './Nav'

const AllInventory = () => {
  return (
    <>
      <Nav />
      <div className='container mx-auto pl-10 md:mt-28 md:pl-10 lg:pl-20 xl:pl-20 md:inline-block text-white
      '>
          <div className=' text-sm flex items-center mt-4  gap-2'>
                <a href='/'>
                <h3 className='text-red-700'>Home</h3>
                </a>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" 
                className="w-3 h-3">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
                <a href='/manufacturer_models'>
                <h3 className='text-red-700'>Showroom</h3>
                </a>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" 
                className="w-3 h-3">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>

                <h3>All Inventory</h3>
          </div>

            <div className='mt-8 text-lg font-bold'>
                <h1>ALL IN-STOCK INVENTORY</h1>
            </div>
            <div>
                 <div className='border mr-8 p-5 text-black border-blue-300 bg-gray-200'>
                      <h2 className='font-bold'>SEARCH INVENTORY</h2>
                      <div className='mt-2 border bg-white rounded-md text-sm   p-3 flex justify-between cursor-pointer'>
                         <input type='text' placeholder='All Conditions' />
                              <svg xmlns="http://www.w3.org/2000/svg" 
                                   fill="none" 
                                   viewBox="0 0 24 24" 
                                   strokeWidth="1.5" 
                                   stroke="currentColor" 
                                   className="w-5 h-5 pr-2 ">
                              <path stroke-linecap="round" 
                                    strokeLinejoin="round" 
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                              </svg>
                      
                      </div>
                 </div> 
            </div>
       </div>
    </>
  )
}

export default AllInventory
