const products = [
  {
    id: 1,
    name: '2024 YAMAHA SUPERJET',
    color: 'White',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
    imageAlt: 'image1',
    price: '$10,699',
  },
  {
    id: 2,
    name: '2024 YAMAHA FX CRUISER SVHO WITH AUDIO',
    color: 'Black / Acid Green',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagethree_bbxnqn.jpg',
    imageAlt: 'image2',
    price: '$19,799',
  },
  {
    id: 3,
    name: '2024 YAMAHA FX CRUISER HO WITH AUDIOT',
    color: 'Deepwater Blue',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686049/Kommander/Featured_Image/featured_imagefour_r4tdvf.jpg',
    imageAlt: 'image3',
    price: '$16,699',
  },
  {
    id: 4,
    name: '2024 YAMAHA GP SVHO WITH AUDIO',
    color: 'Racing Blue',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686049/Kommander/Featured_Image/featured_imagefive_jedsgy.jpg',
    imageAlt: 'image4',
    price: '$17,599',
  },
  {
    id: 5,
    name: '2024 YAMAHA FX SVHO WITH AUDIO',
    color: 'Black',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686049/Kommander/Featured_Image/featured_image1_udzjmi.jpg',
    imageAlt: 'image1',
    price: '$10,699',
  },
  {
    id: 6,
    name: '2024 YAMAHA SUPERJET',
    color: 'White',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
    imageAlt: 'image1',
    price: '$10,699',
  },
  {
    id: 7,
    name: '2024 YAMAHA SUPERJET',
    color: 'White',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
    imageAlt: 'image1',
    price: '$10,699',
  },
  {
    id: 8,
    name: '2024 YAMAHA SUPERJET',
    color: 'White',
    href: '#/',
    imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
    imageAlt: 'image1',
    price: '$10,699',
  },
  // {
  //   id: 9,
  //   name: '2024 YAMAHA SUPERJET',
  //   color: 'White',
  //   href: '#/',
  //   imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
  //   imageAlt: 'image1',
  //   price: '$10,699',
  // },
  // {
  //   id: 10,
  //   name: '2024 YAMAHA SUPERJET',
  //   color: 'White',
  //   href: '#/',
  //   imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
  //   imageAlt: 'image1',
  //   price: '$10,699',
  // },
  // {
  //   id: 11,
  //   name: '2024 YAMAHA SUPERJET',
  //   color: 'White',
  //   href: '#/',
  //   imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
  //   imageAlt: 'image1',
  //   price: '$10,699',
  // },
  // {
  //   id: 12,
  //   name: '2024 YAMAHA SUPERJET',
  //   color: 'White',
  //   href: '#/',
  //   imageSrc: 'https://res.cloudinary.com/doehyebmw/image/upload/v1699686050/Kommander/Featured_Image/featured_imagetwo_i6jqt5.jpg',
  //   imageAlt: 'image1',
  //   price: '$10,699',
  // },

  // More products...
]

export default function Example() {
  return (
    <div className="lg:flex lg:pr-24 md:pl-13">
      <div className="mx-auto max-w-2xl px-4 py-5 sm:px-6 sm:py-20 lg:max-w-7xl lg:px-6 ">
        <h2 className="text-xl font-bold text-white ">Featured Inventory  <button className="text-gray-300 hover:text-red-600 cursor-pointer text-base ml-4">VIEW ALL</button></h2>
        
        <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {products.map((product) => (
            <div key={product.id} >
              <div className="relative ">
                <div className="relative h-72 w-full overflow-hidden rounded-lg">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="h-full w-fit object-cover object-center "
                  />
                </div>
                <div className="relative mt-4 pl-2">
                  <h3 className="text-sm font-medium text-gray-300 truncate ">{product.name}</h3>
                  <p className="mt-1 text-sm text-gray-400">{product.color}</p>
                </div>
                <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                  <div
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                  />
                  <p className="relative text-lg font-semibold text-white">{product.price}</p>
                </div>
              </div>
              <div className="mt-6">
                <a
                  href={product.href}
                  className="relative flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-sm font-medium text-gray-900 hover:bg-red-600 gap-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                  </svg>
                  Contact Us<span className="sr-only">, {product.name}</span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
