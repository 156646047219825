import React from 'react'
import Nav from './Nav'

const NewInventory = () => {
  return (
    <>
        <Nav />
    </>
  )
}

export default NewInventory
