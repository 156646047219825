import LandingPage from '../component/landingpage/LandingPage';
import ManufacturerModels from '../component/showroom/ManufacturerModels';
import AllInventory from '../component/showroom/AllInventory';
import NewInventory from '../component/showroom/NewInventory';
import PreOwnedInventory from '../component/showroom/PreOwnedInventory';


const PublicRoutes =[
    {
      path: '/',
      element: <LandingPage />,
    },
    {
      path: '/home',
      element: <LandingPage />,
    },
    {
      path: '/showroom/manufacturer_models',
      element: <ManufacturerModels />
    },
    {
      path: '/showroom/all_inventory',
      element: <AllInventory />
    },
    {
      path: '/showroom/new_inventory',
      element: <NewInventory />
    },
    {
      path: '/showroom/preowned',
      element: <PreOwnedInventory />
    }
];

export default PublicRoutes;