import React, { useState, useEffect } from 'react';
import {RxDotFilled} from 'react-icons/rx'

const RecentViews = () => {
    const slide = [
      {
        id: 1,
        message: 'Best service hands down! brought my ski in to get a new prop they got me the best pricing and also got my ski done the next day, awesome customer service will only be going here! Thanks, Dustin and the rest of the crew!',
        name: 'Myles Goldberg',
      },
      {
        id: 2,
        message: 'Great group of guys here. On top of their game and know the jetski market better than anyone around. Their service is just as amazing after the sale than during the sale. Thanks, Steve and Dustin and Nick! Super happy with the new toys!',
        name: 'Sam Lle',
      },
      {
        id: 3,
        message: 'I searched every dealer in the Arizona area for a new Superjet and Kommander offered the best price, valuable product information and exceptional service. Dustin and Steve are true professionals in their field and very knowledgeable. I would recommend anyone shopping for a new jetski or looking to service one they already own to make Kommander their first stop or call.',
        name: 'Terry Markle',
      },

    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = slide.length;

    const handleDotClick = (index) => {
      setCurrentSlide(index);
    };

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
      }, 4000); // Auto-slide interval in milliseconds (adjust as needed)
  
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [currentSlide, totalSlides]);
  

    const currentReview = slide[currentSlide];

  return (
    <>
        <div className='flex flex-col  text-white mt-20 justify-center items-center content-center'>
            <h1 className='text-2xl font-bold'>RECENT REVIEWS</h1>

            <div className='container border border-white px-2 mt-5 pt-14 text-sm md:text-lg lg:text-2xl lg:ml-0 lg:mr-28'>
                <p className='text-gray-300 px-8 text-center flex '><h1 className='text-5xl text-start'>"</h1>{currentReview.message}</p>
                <span className='flex items-center justify-center mt-5 text-center mb-20'> - {currentReview.name}</span>

                <hr className='h-2 font-bold'></hr>



               <div className='flex justify-between flex-col py-5 gap-10  lg:flex-row lg:px-6'>
                     <div className='flex justify-center cursor-pointer'>
                       <div className='flex '>
                    {slide.map((_, index) => (
                      <RxDotFilled
                        key={index}
                        className={`text-3xl ${index === currentSlide ? '' : 'text-gray-400'}`}
                        onClick={() => handleDotClick(index)}
                      />
                    ))}
                    </div>
                  </div>
     
                         
                        <div className='flex gap-5 justify-center '>
                        <button className='flex gap-2 text-sm md:text-lg lg:text-2xl font-bold items-center'>READ MORE REVIEWS 
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                        </button>
                        <button className='flex gap-2 text-sm md:text-lg lg:text-2xl font-bold items-center'>LEAVE FEEDBACK
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                        </button>
                        </div>
               </div>
            </div>
        </div>
    </>
  )
}

export default RecentViews
