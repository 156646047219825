import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import PublicRoutes from './routes/PublicRoutes'

// import HomePage from './component/landingpage/HomePage';
// import ManufacturerModels from './component/showroom/ManufacturerModels';
// import AllInventory from './component/showroom/AllInventory';
// import NewInventory from './component/showroom/NewInventory';
// import PreOwnedInventory from './component/showroom/PreOwnedInventory';
// import Promotions from './component/showroom/Promotions';
// import ValueYourTrade from './component/showroom/ValueYourTrade';
// import RequestAModel from './component/showroom/RequestAModel';
// import ScheduleATestRide from './component/showroom/ScheduleATestRide';


const App = () => {
  return (
    <div className='app'>
    <BrowserRouter >
      <Routes>

          {PublicRoutes.map((item, key) => (
            <Route {...item} key={key} />
          ))}
          {/* <Route index  element={ <HomePage />}/>
         <Route path='/'  element={ <HomePage />}/>
          <Route path='/home'  element={ <HomePage />}/>
          <Route path='/showroom/manufacturer_models' element={  <ManufacturerModels /> }/>
          <Route path='/showroom/all_inventory' element={ <AllInventory />   }/>
          <Route path='/showroom/new_inventory' element={ <NewInventory />  }/>
          <Route path='/showroom/preowned' element={ <PreOwnedInventory />  }/>
          <Route path='/showroom/promotions' element={ <Promotions />}/>
          <Route path='/showroom/value' element={ <ValueYourTrade />}/>
          <Route path='/showroom/request' element={ <RequestAModel />}/>
          <Route path='/showroom/schedule' element={ < ScheduleATestRide />}/> */}
      </Routes>
          
           
     </BrowserRouter>
         
    </div>
  )
}

export default App
