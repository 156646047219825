import React from 'react'
import Nav from './Nav';
import Footer from './Footer'

const ManufacturerModels = () => {
  return (
    <>
      <Nav />
      <div className='container mx-auto pl-10 md:mt-28 md:pl-10 lg:pl-20 xl:pl-20 md:inline-block
      '>
          <div className=' text-sm flex items-center text-white mt-4  gap-2'>
                <a href='/'>
                <h3 className='text-red-700'>Home</h3>
                </a>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" 
                className="w-3 h-3">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
                <a href='/manufacturer_models'>
                <h3 className='text-red-700'>Showroom</h3>
                </a>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" 
                className="w-3 h-3">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>

                <h3>Manufacturer Models</h3>
          </div>
          <div className='text-gray-400 mt-10 text-sm pr-8 text-justify md:inline-block'>
               <h1 className='font-bold text-3xl text-white'>MANUFACTURER MODELS</h1>
               <p className='mt-5 '>Kommander Industries is a Phoenix AZ dealer for 
               <a href='#/' className='text-red-700'> Yamaha WaveRunners</a>; 
               <a href='#/' className='text-red-700'> Kymco Scooters</a>, 
               <a href='#/' className='text-red-700'> Motorcycles</a>, 
               <a href='#/' className='text-red-700'> ATVs</a> & 
               <a href='#/' className='text-red-700'> Side-by-Side UTVs</a>; and 
               <a href='#/' className='text-red-700'> Zieman boat trailers</a> & 
               <a href='#/' className='text-red-700'> sport utility trailers</a>. If you don’t see your dream machine among our 
               <a href='#/' className='text-red-700'> new inventory in stock</a>, we can get you any manufacturer model seen here. Jus t 
               <a href='#/' className='text-red-700'> submit your request</a>! And don't miss our 
               <a href='#/' className='text-red-700'> manufacturer promotions</a> for current deals and 
               <a href='#/' className='text-red-700'> PWC financing</a> offers.</p> 
          </div>
          <div className='p-4 bg-white text-gray-500  mt-12 border border-blue-400 font-light mr-8'>
              <h3>Your search returned no results. Please try again with different search filters.</h3>
          </div>
            <ul className='list-disc pr-8 py-4 text-gray-400 text-sm text-justify'>
              <li className='my-4'>Price, if shown and unless otherwise noted, represents the Manufacturer's Suggested Retail Price (MSRP) and does not include government fees, taxes, dealer vehicle freight/preparation, dealer document 
                  preparation charges, labor, installation, or any finance charges (if applicable). MSRP and or final actual sales price will vary depending on options or accessories selected; contact dealer for more details.
              </li>
              <li>Models shown represent the complete line of available manufacturer models and do not reflect actual dealership inventory or availability. For a complete list of current in-stock units, please visit our 
                  <a href='#/' className='text-red-700'>New Inventory</a> and <a href='#/' className='text-red-700'>Pre-Owned</a> Inventory pages.
              </li>
              <li className='my-4'>Images, where available, are manufacturer stock images and may represent models with additional options or features. No guarantee of availability or inclusion of displayed options should be inferred; 
                  contact dealer for more details.
              </li>
          </ul> 

      </div>
        <Footer/>
    </>
  )
}

export default ManufacturerModels
